export const skillsList = [
    "HTML",
    "JavaScript",
    "TypeScript",
    "JS Immutability",
    "CSS BEM convention",
    "CSS Flexbox",
    "CSS Grid",
    "React",
    "React-Redux",
    "React-Saga",
    "React Hooks",
    "styled-components",
    "Working with API (fetch, axios)",
    "Error handling",
    "NPM",
    "Git",
    "GitHub Pull Requests & Reviews",
    "Teamwork",
    "Scrum basics",
    "Selling and negotiations skills",
    "Trello",
    "Web Design",
    "Adobe Photoshop (Basic)",
    "Adobe Illustrator",
    "Figma (Basic)",
    "Communicative English language",
  ];

  export const toLearnList = [
      "React Query",
      "React Native",
      "Angular",
      "Mastering Illustrator",
      "Mastering Figma",
      "한국어 (Korean language)",
  ];